body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  width: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px     rgba(0,0,0,0.3); 
  box-shadow: inset 0 0 6px     rgba(0,0,0,0.3);
  border-radius: 14px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffee00;
  border-radius: 14px;
  -webkit-box-shadow: inset 0 0 6px     rgba(0,0,0,0.2); 
  box-shadow: inset 0 0 6px     rgba(0,0,0,0.2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
} 

body {
  overflow-x: hidden;
}

.modalimagestyle {
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
  ;
}
